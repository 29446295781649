import data from "./Footer-Data";
import IndconLogo from '../img/EMPRESAS/INDCON.png'
import '../styles/footer.css'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaWhatsapp, FaMapMarkerAlt } from 'react-icons/fa'
export default function Footer() {
  return (
    <section className="Footer">
      <div className="first-item">
        <ul>
          {data.map((item, index) => (
            <li key={index} className={item.liname}>
              {" "}
              {item.title}
            </li>
          ))}
        </ul>
      </div>
      <div className="logo-logo">
        <div className="logo">
        </div>
      </div>
      <div className="middle-item">
        <div className="addr-addr">
          <FaMapMarkerAlt style={{ width: 30, height: 30, color: '#bbab3b', }} />
          <h3>Rua José Pires - Centro/Atibaia - SP</h3>
        </div>
      </div>
      <div className="second-item">
        <div className="social-icons-footer">
          <div className="item-footer">
            <FaWhatsapp style={{ width: 24, height: 24 }} />
          </div>
          <div className="item-footer">
            <FaInstagram style={{ width: 24, height: 24 }} />
          </div>
          <div className="item-footer">
            <FaFacebookF style={{ width: 24, height: 24 }} />

          </div>
          <div className="item-footer">
            <FaLinkedinIn style={{ width: 24, height: 24 }} />
          </div>

        </div>

      </div>
      <div className="third-item">
        <h3> Incon Holding - All Rights Reserved - 2022 </h3>
        <h3> Desenvolvido por Igor Gomes</h3>
      </div>
    </section>
  );
}