import Footer from '../components/Footer'
import { React, useState } from 'react'
import { Link } from 'react-router-dom'
import '../styles/Navbar.css'
import Logo from '../img/logo.png'
import '../styles/Empresas.css'
import Modal from 'react-modal'



Modal.setAppElement("#root")


function Empresas() {

  const [modalisOpen, setIsOpen] = useState(false)
  const [modalisOpenConstrubank, setIsOpenConstrubank] = useState(false)
  const [modalisOpenIndcon, setIsOpenIndcon] = useState(false)
  const [modalisOpenMakeHome, setIsOpenMakeHome] = useState(false)
  const [modalisOpenMakeHouse, setIsOpenMakeHouse] = useState(false)
  const [modalisOpenConstruInvest, setIsOpenConstruInvest] = useState(false)
  const [modalisOpenFacilit, setIsOpenFacilit] = useState(false)
  const [modalisOpenC2M, setIsOpenC2M] = useState(false)
  const [modalisOpenMdk, setIsOpenMdk] = useState(false)
  const [modalisOpenBmImports, setIsOpenBmImports] = useState(false)
  const [modalisOpenBmTech, setIsOpenBmTech] = useState(false)
  const [modalisOpenBmShop, setIsOpenBmShop] = useState(false)
  const [modalisOpenBmPet, setIsOpenBmPet] = useState(false)
  const [modalisOpenBmMetais, setIsOpenBmMetais] = useState(false)
  const [modalisOpenBmMotors, setIsOpenBmMotors] = useState(false)
  const [modalisOpenBmFit, setIsOpenBmFit] = useState(false)








  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };
  const LogoStyle = {
    width: 100
  }

  function openModalConstrubank() {
    setIsOpenConstrubank(true)
  }

  function closeModalConstrubank() {
    setIsOpenConstrubank(false)
  }
  function openModalIndcon() {
    setIsOpenIndcon(true)
  }

  function closeModalIndcon() {
    setIsOpenIndcon(false)
  }
  function openModalMakeHouse() {
    setIsOpenMakeHouse(true)
  }

  function closeModalMakeHouse() {
    setIsOpenMakeHouse(false)
  }

  function openModalMakeHome() {
    setIsOpenMakeHome(true)
  }

  function closeModalMakeHome() {
    setIsOpenMakeHome(false)
  }

  function openModalFacilit() {
    setIsOpenFacilit(true)
  }

  function closeModalFacilit() {
    setIsOpenFacilit(false)
  }
  function openModalConstruInvest() {
    setIsOpenConstruInvest(true)
  }

  function closeModalConstruInvest() {
    setIsOpenConstruInvest(false)
  }

  function openModalC2M() {
    setIsOpenC2M(true)
  }

  function closeModalC2M() {
    setIsOpenC2M(false)
  }

  function openModalMDK() {
    setIsOpenMdk(true)
  }

  function closeModalMDK() {
    setIsOpenMdk(false)
  }

  function openModalBmImports() {
    setIsOpenBmImports(true)
  }

  function closeModalBmImports() {
    setIsOpenBmImports(false)
  }

  function openModalBmTech() {
    setIsOpenBmTech(true)
  }

  function closeModalBmTech() {
    setIsOpenBmTech(false)
  }
  function openModalBmShop() {
    setIsOpenBmShop(true)
  }

  function closeModalBmShop() {
    setIsOpenBmShop(false)
  }
  function openModalBmPet() {
    setIsOpenBmPet(true)
  }

  function closeModalBmPet() {
    setIsOpenBmPet(false)
  }

  function openModalBmMetais() {
    setIsOpenBmMetais(true)
  }

  function closeModalBmMetais() {
    setIsOpenBmMetais(false)
  }

  function openModalBmMotors() {
    setIsOpenBmMotors(true)
  }

  function closeModalBmMotors() {
    setIsOpenBmMotors(false)
  }

  function openModalBmFit() {
    setIsOpenBmFit(true)
  }

  function closeModalBmFit() {
    setIsOpenBmFit(false)
  }
  const openConstrubank = () => {
    window.open("http://construbank.net.br/", '_blank').focus();
  }
  const openBmTech = () => {
    window.open("https://bmitech.com.br/", '_blank').focus();
  }
  const openBmImports = () => {
    window.open("https://bmimports.com.br/", '_blank').focus();
  }
  

  return (
    <>
      <nav className="nav" style={{ backgroundColor: 'white' }}>
        <Link to={'/'}>
          <span className="nav__brand"><img style={LogoStyle} src={Logo} /></span>
        </Link>
        <ul className={active}>
          <li>
            <Link to={'/'}>
              <span className='navbar-item'>Página Inicial</span>
            </Link>
          </li>
          <li>
            <Link to={'#about'} >
              <span className='navbar-item'>Sobre Nós</span>
            </Link>
          </li>
          <li>
            <Link to={'#empresas'}>
              <span className='navbar-item'>Empresas</span>
            </Link>
          </li>
          <li>
            <Link to={'#contato'}>
              <span className='navbar-item'>Contato</span>
            </Link>
          </li>


        </ul>
        <div onClick={navToggle} className={icon}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </nav>
      <div className='body-empresas'>
        <div className='containerx two'>
          A nossa Holding em detalhes
        </div>
        <div className='containerx one'>
          <div className='box' id='indcon' onClick={openModalIndcon}></div>
          <Modal
            isOpen={modalisOpenIndcon}
            onRequestClose={closeModalIndcon}
            contentLabel="Example"
            overlayClassName="modal-overlay"
            className="modal-content">
            <div className='conteudo-modal'>
              <div className='text-modal'>

                <p className='paragraph-modal'>Holding sediada na cidade de Atibaia, São Paulo, com mais de 15 anos de experiência em gestão e criação de novas empresas.<br></br><br></br>
Como uma boa Sociedade gestora matriz de participações sociais, contamos com + de 10 empresas em nosso grupo, de diferentes seguimentos, sendo eles: construção, finanças e investimentos, importação e exportação, vendas, etc. <br></br><br></br>
Temos como objetivo ampliar nossa visão em diversos nichos e estabelecer valores em todas as nossas marcas, trazendo sempre um diferencial em nossos produtos e serviços, afinal, somos um, mas também, somos muitos.</p>

              </div>
              <div className='button-class'>
                <button onClick={() => {alert("Redirecionando...")}}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                      </svg>
                    </div>
                  </div>
                  <span>Visite o site</span>
                </button>
                <button onClick={closeModalIndcon}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="#ffff" d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z" />

                      </svg>
                    </div>
                  </div>
                  <span>Fechar</span>
                </button>
              </div>
            </div>
            <div className='conteudo-modal banner indcon'></div>

          </Modal>
          <div className='box' id='makehouse' onClick={openModalMakeHouse} ></div>
          <Modal
            isOpen={modalisOpenMakeHouse}
            onRequestClose={closeModalMakeHouse}
            contentLabel="Example"
            overlayClassName="modal-overlay"
            className="modal-content">
            <div className='conteudo-modal'>
              <div className='text-modal'>

                <p className='paragraph-modal'>Empresa Grupo Indcon, Sua casa por assinatura... em breve em Atibaia, São Paulo!</p>

              </div>
              <div className='button-class'>
                <button onClick={() => {alert("Redirecionando...")}}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                      </svg>
                    </div>
                  </div>
                  <span>Visite o site</span>
                </button>
                <button onClick={closeModalMakeHouse}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="#ffff" d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z" />

                      </svg>
                    </div>
                  </div>
                  <span>Fechar</span>
                </button>
              </div>
            </div>
            <div className='conteudo-modal banner makehouse'></div>

          </Modal>
          <div className='box' id='makehome' onClick={openModalMakeHome}></div>
          <Modal
            isOpen={modalisOpenMakeHome}
            onRequestClose={closeModalMakeHome}
            contentLabel="Example"
            overlayClassName="modal-overlay"
            className="modal-content">
            <div className='conteudo-modal'>
              <div className='text-modal'>

                <p className='paragraph-modal'>Empresa Grupo Indcon, Incorporadora e Construtora sediada na cidade de Atibaia.
</p>

              </div>
              <div className='button-class'>
                <button onClick={() => {alert("Redirecionando...")}}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                      </svg>
                    </div>
                  </div>
                  <span>Visite o site</span>
                </button>
                <button onClick={closeModalMakeHome}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="#ffff" d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z" />

                      </svg>
                    </div>
                  </div>
                  <span>Fechar</span>
                </button>
              </div>
            </div>
            <div className='conteudo-modal banner makehome'></div>
          </Modal>
          
          <div className='box' id='construinvest' onClick={openModalConstruInvest}></div>
          <Modal
            isOpen={modalisOpenConstruInvest}
            onRequestClose={closeModalConstruInvest}
            contentLabel="Example"
            overlayClassName="modal-overlay"
            className="modal-content">
            <div className='conteudo-modal'>
              <div className='text-modal'>

                <p className='paragraph-modal'>Empresa Grupo Indcon fundada em 2021.  Na necessidade e no surgimento de modalidades de  investimentos no novo mercado, o Grupo,  com uma vasta experiência de 15 anos em empresas,  negócios e empreendimentos, resolveu oferecer  oportunidades de investimentos, antes restrita à grandes  investidores, de forma democrática. <br></br><br></br> Somos uma Fintech com a missão de democratizar  investimentos em empreendimentos da economia real  e financiar empreendedores de forma coletiva.  </p>

              </div>
              <div className='button-class'>
                <button onClick={() => {alert("Redirecionando...")}}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                      </svg>
                    </div>
                  </div>
                  <span>Visite o site</span>
                </button>
                <button onClick={closeModalConstruInvest}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="#ffff" d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z" />

                      </svg>
                    </div>
                  </div>
                  <span>Fechar</span>
                </button>
              </div>
            </div>
            <div className='conteudo-modal banner construinvest'></div>
          </Modal>
          <div className='box' id='construbank' onClick={openModalConstrubank}></div>
          <Modal
            isOpen={modalisOpenConstrubank}
            onRequestClose={closeModalConstrubank}
            contentLabel="Example"
            overlayClassName="modal-overlay"
            className="modal-content">
            <div className='conteudo-modal'>
              <div className='text-modal'>

                <p className='paragraph-modal'>A <strong style={{ color: "#3c4494" }}>CONSTRUBANK</strong> é especializada na estruturação de operações, bem como na captação de recursos nos segmentos exclusivos para aquisição e securitização de Ativos Empresariais.</p>

              </div>
              <div className='button-class'>
                <button onClick={openConstrubank}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                      </svg>
                    </div>
                  </div>
                  <span>Visite o site</span>
                </button>
                <button onClick={closeModalConstrubank}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="#ffff" d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z" />

                      </svg>
                    </div>
                  </div>
                  <span>Fechar</span>
                </button>
              </div>
            </div>
            <div className='conteudo-modal banner'></div>

          </Modal>
          
          
          <div className='box' id='bmimports' onClick={openModalBmImports}></div>
          <Modal
            isOpen={modalisOpenBmImports}
            onRequestClose={closeModalBmImports}
            contentLabel="Example"
            overlayClassName="modal-overlay"
            className="modal-content">
            <div className='conteudo-modal'>
              <div className='text-modal'>

                <p className='paragraph-modal'>A <strong>BM Imports</strong> é uma empresa que cuida da importação e revenda de produtos de altíssima qualidade, com os melhores preços do mercado.</p>

              </div>
              <div className='button-class'>
                <button onClick={openBmImports}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                      </svg>
                    </div>
                  </div>
                  <span>Visite o site</span>
                </button>
                <button onClick={closeModalBmImports}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="#ffff" d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z" />

                      </svg>
                    </div>
                  </div>
                  <span>Fechar</span>
                </button>
              </div>
            </div>
            <div className='conteudo-modal banner bmimports'></div>
          </Modal>
          <div className='box' id='bmtech' onClick={openModalBmTech}></div>
          <Modal
            isOpen={modalisOpenBmTech}
            onRequestClose={closeModalBmTech}
            contentLabel="Example"
            overlayClassName="modal-overlay"
            className="modal-content">
            <div className='conteudo-modal'>
              <div className='text-modal'>

                <p className='paragraph-modal'>A <strong>BM Tech</strong> é um distribuidor brasileiro especializado em soluções de Inteligência Artificial via Software, Câmeras IP, redes e cabeamentos.</p>

              </div>
              <div className='button-class'>
                <button onClick={openBmTech}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                      </svg>
                    </div>
                  </div>
                  <span>Visite o site</span>
                </button>
                <button onClick={closeModalBmTech}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="#ffff" d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z" />

                      </svg>
                    </div>
                  </div>
                  <span>Fechar</span>
                </button>
              </div>
            </div>
            <div className='conteudo-modal banner bmtech'></div>
          </Modal>
          <div className='box' id='bmshop' onClick={openModalBmShop}></div>
          <Modal
            isOpen={modalisOpenBmShop}
            onRequestClose={closeModalBmShop}
            contentLabel="Example"
            overlayClassName="modal-overlay"
            className="modal-content">
            <div className='conteudo-modal'>
              <div className='text-modal'>

                <p className='paragraph-modal'>Loja virtual para venda de produtos BM GROUP.</p>

              </div>
              <div className='button-class'>
                <button onClick={() => {alert("Redirecionando...")}}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                      </svg>
                    </div>
                  </div>
                  <span>Visite o site</span>
                </button>
                <button onClick={closeModalBmShop}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="#ffff" d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z" />

                      </svg>
                    </div>
                  </div>
                  <span>Fechar</span>
                </button>
              </div>
            </div>
            <div className='conteudo-modal banner bmshop'></div>
          </Modal>
          <div className='box' id='bmpet' onClick={openModalBmPet}></div>
          <Modal
            isOpen={modalisOpenBmPet}
            onRequestClose={closeModalBmPet}
            contentLabel="Example"
            overlayClassName="modal-overlay"
            className="modal-content">
            <div className='conteudo-modal'>
              <div className='text-modal'>

                <p className='paragraph-modal'>A BM Pet é uma empresa BM GROUP e um distribuidor brasileiro especializado em produtos Pet.</p>

              </div>
              <div className='button-class'>
                <button onClick={() => {alert("Redirecionando...")}}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                      </svg>
                    </div>
                  </div>
                  <span>Visite o site</span>
                </button>
                <button onClick={closeModalBmPet}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="#ffff" d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z" />

                      </svg>
                    </div>
                  </div>
                  <span>Fechar</span>
                </button>
              </div>
            </div>
            <div className='conteudo-modal banner bmpet'></div>
          </Modal>
          <div className='box' id='bmmetais' onClick={openModalBmMetais}></div>
          <Modal
            isOpen={modalisOpenBmMetais}
            onRequestClose={closeModalBmMetais}
            contentLabel="Example"
            overlayClassName="modal-overlay"
            className="modal-content">
            <div className='conteudo-modal'>
              <div className='text-modal'>

                <p className='paragraph-modal'>A BM METAIS é uma empresa BM GROUP e um distribuidor brasileiro de produtos para casa e construção, como por exemplo: torneiras, chuveiros e etc.</p>

              </div>
              <div className='button-class'>
                <button onClick={() => {alert("Redirecionando...")}}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                      </svg>
                    </div>
                  </div>
                  <span>Visite o site</span>
                </button>
                <button onClick={closeModalBmMetais}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="#ffff" d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z" />

                      </svg>
                    </div>
                  </div>
                  <span>Fechar</span>
                </button>
              </div>
            </div>
            <div className='conteudo-modal banner bmmetais'></div>
          </Modal>
          <div className='box' id='bmmotors' onClick={openModalBmMotors}></div>
          <Modal
            isOpen={modalisOpenBmMotors}
            onRequestClose={closeModalBmMotors}
            contentLabel="Example"
            overlayClassName="modal-overlay"
            className="modal-content">
            <div className='conteudo-modal'>
              <div className='text-modal'>

                <p className='paragraph-modal'>A BM MOTORS é uma empresa BM GROUP e um distribuidor brasileiro de Veículos Automotores. </p>

              </div>
              <div className='button-class'>
                <button onClick={() => {alert("Redirecionando...")}}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                      </svg>
                    </div>
                  </div>
                  <span>Visite o site</span>
                </button>
                <button onClick={closeModalBmMotors}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="#ffff" d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z" />

                      </svg>
                    </div>
                  </div>
                  <span>Fechar</span>
                </button>
              </div>
            </div>
            <div className='conteudo-modal banner bmmotors'></div>
          </Modal>
          <div className='box' id='bmfit' onClick={openModalBmFit}></div>
          <Modal
            isOpen={modalisOpenBmFit}
            onRequestClose={closeModalBmFit}
            contentLabel="Example"
            overlayClassName="modal-overlay"
            className="modal-content">
            <div className='conteudo-modal'>
              <div className='text-modal'>

                <p className='paragraph-modal'>A BM FIT é uma empresa BM GROUP e um distribuidor brasileiro especializado em produtos Fitness.</p>

              </div>
              <div className='button-class'>
                <button onClick={() => {alert("Redirecionando...")}}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                      </svg>
                    </div>
                  </div>
                  <span>Visite o site</span>
                </button>
                <button onClick={closeModalBmFit}>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="#ffff" d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z" />

                      </svg>
                    </div>
                  </div>
                  <span>Fechar</span>
                </button>
              </div>
            </div>
            <div className='conteudo-modal banner bmfit'></div>
          </Modal>
        </div>


      </div>
      <Footer />
    </>
  )
}

export default Empresas