const data = [
    {
        title: 'PÁGINA INICIAL',
        liname: 'li-footer',
        aname: 'a-footer'
    },
    {
        title: 'SOBRE NÓS',
        liname: 'li-footer',
        aname: 'a-footer'
    },
    {
        title: 'EMPRESAS',
        liname: 'li-footer',
        aname: 'a-footer'
    },
    {
        title: 'CONTATO',
        liname: 'li-footer',
        aname: 'a-footer'
    },
  
    
]
export default data