import React, { useRef } from 'react'
import Navbar from '../components/Navbar'
import '../styles/Homepage.css'
import { EmpresasData } from '../components/ImagesData'
import Arrow from '../img/arrow.png'
import Footer from '../components/Footer'
import {BsFillTelephoneFill} from 'react-icons/bs'
import {MdOutlineEmail} from 'react-icons/md'
import { Link } from 'react-router-dom'

function Home() {

  const handleLeftClick = (e) => {
    e.preventDefault()
    carousel.current.scrollLeft -= carousel.current.offsetWidth


  }
  const handleRightClick = (e) => {
    e.preventDefault()
    carousel.current.scrollLeft += carousel.current.offsetWidth

  }

  const carousel = useRef(null)

  return (
    <>
      <Navbar />
      <div className='About' id='about'>
        <div id='box-1'>
        </div>
        <div id='box-2'>
          <h3 >O Grupo Indcon</h3>
          <p>Holding sediada na cidade de Atibaia, São Paulo, com mais de 15 anos de experiência em gestão e criação de novas empresas.
          </p>
          <p>
            Como uma boa Sociedade gestora matriz de participações sociais, contamos com + de 10 empresas em nosso grupo, de diferentes seguimentos, sendo eles: construção, finanças e investimentos, importação e exportação, vendas, etc.
          </p>
          <p>
            Temos como objetivo ampliar nossa visão em diversos nichos e estabelecer valores em todas as nossas marcas, trazendo sempre um diferencial em nossos produtos e serviços, afinal, <strong>somos um, mas também, somos muitos.</strong>
          </p>

        </div>

      </div>
      <div className='Empresas' id='empresas'>
        <div className='buttons-carrousel1'>
          <button className='carousel-btn1' onClick={handleLeftClick}><img src={Arrow} /></button>
        </div>
        <div className='container'>
          <div className='carousel' ref={carousel}>
            {EmpresasData.map((item, index) => {
              const { image } = item
              return (
                <div className='item' key={index}>
                  <div className='image'>
                    <img src={image} alt='' />
                  </div>
                </div>
              )
            })}
          </div>     <div className='btn-group'>
          <Link to={'/empresas'}>
            <button className="btn">
       
              Ver todas as marcas
             
            </button>
            </Link>
          </div>

        </div>
        <div className='buttons-carrousel'>
          <button className='carousel-btn' onClick={handleRightClick}><img src={Arrow} /></button>
        </div>
      </div>
      <div className='contact-div' id='contato'>
        <div className='container-contact'>
          <BsFillTelephoneFill style={{width: 50, height: 50, marginTop: 30, marginBottom: 40, color: '#bbab3b'}}/>
          <h3>Fale conosco através do número</h3>
          <h3><strong>11 3113-2306</strong></h3>
          <h2></h2>
        </div>
        <div className='container-contact'>
          <MdOutlineEmail style={{width: 51, height: 51, marginTop: 30, marginBottom: 40, color: '#bbab3b'}}/>
          <h3>Ou nos envie um e-mail</h3>
          <h3><strong>contato@grupoindcon.com.br</strong></h3>
          <h2></h2>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Home