import BMFitLogo from '../img/EMPRESAS/BM-FIT.png'
import ConstruBankLogo from '../img/EMPRESAS/CONSTRUBANK.png'
import ConstruInvestLogo from '../img/EMPRESAS/CONSTRUINVEST.png'
import BMImportsLogo from '../img/EMPRESAS/BM-IMPORTS.png'
import MakeHomeLogo from '../img/EMPRESAS/MAKE-HOUSE.png'
import BmShopLogo from '../img/EMPRESAS/BM-SHOP.png'



export const EmpresasData = [
    {
        image: BmShopLogo
    },

    {
        image: ConstruBankLogo
    },
    {
        image: ConstruInvestLogo
    },
    {
        image: BMImportsLogo
    },

    {
        image: MakeHomeLogo
    }
]

