import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Empresas from './pages/Empresas';
import Contato from './pages/Contato';
import NossaHistoria from './pages/NossaHistoria';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route element={<Home/>} path='/' exact/>
        <Route element={<Empresas/>} path='/empresas' />
      </Routes>
    </Router>
    </>
  );
}

export default App;
