import { React, useState } from 'react'
import { Link } from 'react-router-dom'
import '../styles/Navbar.css'
import Logo from '../img/logo.png'
import desktopImage from '../img/desktop.jpg'
import mobileImage from '../img/desktop.jpg'
import Typewriter from 'typewriter-effect'

import '../styles/Header.css'
import { HashLink } from 'react-router-hash-link'

function Navbar() {
    const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage;

    const NavbarData = [
        {
            title: 'Página Inicial',
            path: '/',
            cName: 'navbar-item'
        },

        {
            title: 'Contato',
            path: '/contato',
            cName: 'navbar-item'
        },
    ]
    const Redirectdata = [
        {
            title: 'Sobre nós',
            path: '#about',
            cName: 'navbar-item'
        },
        {
            title: 'Empresas',
            path: '/empresas',
            cName: 'navbar-item'
        },
    ]
    const LogoStyle = {
        width: 100
    }

    const [active, setActive] = useState("nav__menu");
    const [icon, setIcon] = useState("nav__toggler");
    const navToggle = () => {
        if (active === "nav__menu") {
            setActive("nav__menu nav__active");
        } else setActive("nav__menu");

        // Icon Toggler
        if (icon === "nav__toggler") {
            setIcon("nav__toggler toggle");
        } else setIcon("nav__toggler");
    };
    return (
        <>
            <div className='hero'>


                <nav className="nav">
                    <Link to={'/'}>
                        <span className="nav__brand"><img style={LogoStyle} src={Logo} /></span>
                    </Link>
                    <ul className={active}>
                        <li>
                            <Link to={'/'}>
                                <span className='navbar-item'>Página Inicial</span>
                            </Link>
                        </li>
                        <li>
                            <HashLink to={'#about'} >
                                <span className='navbar-item'>Sobre Nós</span>
                            </HashLink>
                        </li>
                        <li>
                            <Link to={'/empresas'}>
                                <span className='navbar-item'>Empresas</span>
                            </Link>
                        </li>
                        <li>
                            <HashLink to={'#contato'}>
                                <span className='navbar-item'>Contato</span>
                            </HashLink>
                        </li>
                        

                    </ul>
                    <div onClick={navToggle} className={icon}>
                        <div className="line1"></div>
                        <div className="line2"></div>
                        <div className="line3"></div>
                    </div>
                </nav>
                {/* <div className='text-header' style={{ height: '85vh', backgroundColor: 'transparent', margin: '0 auto', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 40, textAlign: 'center', fontWeight:700}}>
                    <div  className='box-text' style={{backgroundColor: 'rgba(255,255,255,1)', padding: 20, border:'2px solid #bbab3b'}}>
                <Typewriter
                    onInit={(typewriter) => {
                        typewriter
                            .typeString('<span style="font-weight: 400">Somos muitos, somos o futuro</span>'
                            )
                            .changeDelay(2)
                            .start()
                    }} />
                    </div>
                </div> */}
            </div>


        </>
    )
}

export default Navbar
